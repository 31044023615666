@media (max-width: 1439px) {

  // Mix Layout

  .main-content {
    padding: 2rem;
  }

  .main-content__h1 {
    font-size: 2rem;
    line-height: normal;
  }

  .main-content__label,
  .main__total {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .analytics-graphs-v2-view__form-row {
    max-height: 3rem;
    min-height: 3rem;
  }

  .analytic-graph-v2-chart-form .analytics-graphs-v2-view__form .analytics-graphs-v2-view__graph-v2-radio .analytics-graphs-v2-view__form-row label.main-content__radio-label {
    padding: 0 1.6rem;

    font-size: 1.2rem;
    line-height: normal;
  }

  .main-content__input,
  .new-projects__filters .apply-filters,
  .reset-compare-products-btn,
  .main-btn,
  .main-btn__general {
    font-size: 1.2rem;
    line-height: normal;

    max-height: 3rem;
    min-height: 3rem;
  }

  .main-modal__form-col-input {
    font-size: 1.2rem;
    line-height: normal;

    max-height: 3rem;
    min-height: 3rem;
  }

  .compare-products-rows .product-controls-labels p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .reset-compare-products-btn,
  .main-btn__general {
    margin-left: 0.8rem;
  }

  .compare-products__wrap-btns .reset-compare-products-btn,
  .product-finder-actions .shared-filters-btns a,
  .product-finder-actions .shared-filters-btns button {
    height: fit-content !important;
  }

  .help-icon {
    margin: 0 0.8rem 0 0;
  }

  .product-finder-actions {
    min-height: 3rem;
  }

  .main-content__info {
    margin-right: 2rem;
  }

  .product__info.main-content__info {
    width: 22rem;
    padding: 1.6rem 1.2rem;

    .product__total {
      margin-bottom: 1rem;
    }

    .product__wrap-title {
      margin-bottom: 1rem;
    }

    .product__wrap-btns button {
      margin-bottom: 1rem;
    }

    .filter-number-range input {
      min-width: 8rem;
    }

    .toggle__show-block,
    .product__filters {
      * {
        font-size: 1.2rem;
        line-height: normal;
      }

      i {
        font-size: 1.6rem;
        line-height: 1.6rem;
      }

      p {
        padding: 0;
      }
    }

    .product__filters {
      padding: 0.8rem;
    }
  }

  .cost-modal__calculator_btn {
    white-space: nowrap;

    &:before {
      top: 0.1rem;
    }
  }

  .main-content__form {
    padding: 1.6rem;
  }

  // Mix Layout End

  // Select Layout

  .select-title,
  .select-custom-wrap {
    max-height: 3rem !important;
    min-height: 3rem !important;

    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    .select-custom-wrap {
      max-height: 3rem !important;
      min-height: 3rem !important;
    }

    & > div,
    & > div > div,
    & > div > div > div,
    & > div > div > div + div,
    .select__control {
      padding-top: 0;
      padding-bottom: 0;

      line-height: normal;

      max-height: 3rem !important;
      min-height: 3rem !important;
    }

    & > div + div,
    [class*="-menu"],
    [class*="-MenuList"],
    .select__menu-list,
    .select__menu-notice {
      max-height: 30vh !important;
    }

    div[aria-hidden="true"] {
      padding: 0.4rem;
    }

    [class*="-control"] {
      div {
        max-height: 2.8rem !important;
        min-height: fit-content !important;
      }

      [class*="-IndicatorsContainer"] {
        min-height: 2.8rem !important;

        div {
          min-height: 2.8rem !important;
        }
      }
    }

    svg {
      height: 1.8rem;
    }

    [class*="-multiValue"] {
      display: flex;
      align-items: center;

      div {
        font-size: 1.2rem;
        line-height: normal;

        &:last-child {
          max-height: 1.4rem;
        }
      }

      svg {
        height: 1.4rem;
      }
    }
  }

  // Select End Layout

  // Sidebar Layout

  .active-sidebar .main-sidebar {
    width: 18rem;
  }

  .main-sidebar__list {
    .main-sidebar__list-item {
      .main-sidebar__list-link {
        padding: 1.2rem;

        .main-sidebar__list-text {
          top: 1.75rem;
          left: 5rem;

          line-height: normal;
        }

        &.submenu-item:after {
          left: 15rem;
        }
      }

      .main-sidebar__list-item {
        .main-sidebar__list-link .main-sidebar__list-text {
          top: 2.2rem;
        }
      }
    }

    .main-sidebar__multilevel-item .menu-items {
      left: 18rem;

      li {
        padding: 0.6rem;

        .menu-sub-items li {
          padding: 0.1rem;
        }
      }
    }
  }


  .main-table__body-link-user > img,
  .main-table__body-link-user > .icon-svg,
  .main-table__body-link-user,
  .main-table__body-link-user svg {
    min-width: 2rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  .product__wrap-block.main-content__wrap-block .help-wrap .main-circle.help-icon {
    right: -1rem;
  }

  // Sidebar End Layout

  // Table Layout

  .main-table {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .connections__wrap-body .connections-table-head-column.FY {
    box-sizing: content-box;

    * {
      box-sizing: content-box;
    }
  }

  .myProduct__wrap .main-table th:first-child,
  .publication-groups__wrap-main .main-table th:first-child {
    padding-left: 1.2rem;
    padding-right: 0.8rem;
  }

  .main-table__head:not(.connections-table-head-column) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    &:first-child {
      padding-left: 1.2rem;
      padding-right: 0.8rem;
    }

    span, a {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .main-table__body {
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    &:first-child {
      padding-left: 1.2rem;
      padding-right: 0.8rem;

      .main-table__body-link {
        min-width: 16rem;
      }
    }

    span, a {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  // Table End Layout
}