#zendeskLauncher {
  background-color: #333333;
  padding: 10px;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  color: white;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 550;
  white-space: nowrap;
}