@mixin back-circle() {
    content: "";
    position: absolute;
    width: 74.3rem;
    height: 74.3rem;
    background: #3b8ce9;
    z-index: 0;
    border-radius: 50%;
}
@mixin container() {
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 3;
    box-sizing: border-box;
}
@mixin square() {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 0.4rem;
}
@mixin circle() {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50%;
}
@mixin table-body-text() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 19rem;
    display: block;
}
@mixin block-shadow() {
    box-shadow: 0 0.1rem 0.4rem #c5cee0;
    border-radius: 0.4rem;
    box-sizing: border-box;
}
@mixin status__button--modern {
    content: "";
    width: calc(100% + 2rem);
    height: 50%;
    position: absolute;
    left: 50%;
    border-radius: 0.4rem;
    background-color: inherit;
    z-index: 0;
}
