pre {
  white-space: initial;
}

.main-text {
  &-right{
    text-align: right;
  }
  &-center{
    text-align: center;
  }
  &-bold{
    font-weight: bold;
  }
}

.projects__tab-item-0,
.projects__tab-item-1,
.projects__tab-item-2,
.projects__tab-item-3,
.projects__tab-item-4 {
  .projects__table-head-row.main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    font-weight: initial;
    font-size: 12px;
    display: inline-block;
    min-width: 100%;
  }
}

.connections__tab-item-0 {
  .connections__table-head-row.main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    font-weight: initial;
    font-size: 12px;
    display: inline-block;
  }

  .FY .connections__table-head-row.main-table__head-row .main-is-disabled {
    min-width: 100%;
  }
}

.grade-view__tab-item-6 {
  .grade-view__table-head-row.main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    font-weight: initial;
    font-size: 12px;
    display: inline-block;
    min-width: 100%;
  }
}

.plants__wrap-body,
.products__wrap-body,
.companies-content__wrap-body,
.contacts-content__wrap,
.vehicle-view__wrap-body,
.my-forecast-view__wrap-body,
.connection-view__wrap-body,
.grade-view__wrap-body
{
  .main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    display: inline-block;
    min-width: 100%;
  }
}

.products__tab-item-1,
.companies-content__tab-item-1,
.contacts-content__tab-item-0,
.vehicle-view__tab-item-1,
.my-forecast-view__tab-item-0,
.my-forecast-view__tab-item-1,
.connection-view__tab-item-0,
.connection-view__tab-item-1,
.grade-view__tab-item-1,
.contacts-content__tab-item-1,
.grade-view__tab-item-0 {
  .main-table__head-row .main-is-disabled {
    font-weight: initial;
    font-size: 12px;
  }
}

.dashboard__card-data-card{
   justify-content: center;
}

.dashboard__card-total span .unit_value{
    display: initial;
    font-size: 24px;
}

// hide delete button from "Help Pages"
.help-page__table-body-row {
  .help-page__table-body-circle:has(> .icon_delete) {
    display: none;
  }
}

.login__form-row {
  .text-wrap {
    padding-top: 5px;
  }
}

// hide delete button from "Admin Import Templates"
.import-template__table-body-row {
  .import-template__table-body-circle:has(> .icon_edit) {
    display: none;
  }
  .import-template__table-body-circle:has(> .icon_delete) {
    display: none;
  }
}

.main-content__form {
  &.download-report {
    width: fit-content;
    margin: 0 auto;
  }
}

.publication-groups__form-block-wrap-flex {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: .4rem;
  padding: 1.6rem 2.4rem;
  width: auto;
  height: 100%;
}

.publication-groups__form-row-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.375rem 0;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: .8rem;
  }
  .publication-groups__form-block-data {
    flex: 1 1 50%;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
  }
  .publication-groups__role {
    flex: 1 1 50%;
    font-weight: 500;
    margin-right: 0.775rem;
  }
}

.publication-groups__form-row {
  display: flex;
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}

.publication-groups__form-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &:not(:last-child) {
    margin-right: 1.2rem;
  }
}

.publication-groups__block-header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.publication-groups__publications {
  max-height: 50rem;
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.product__label.main-content__label {
  display: block;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
}

.publication-groups__wrap-input,
.product__wrap-input {
  margin-bottom: 0;
}

.publication-groups__form-items-list {
  max-height: 500px;
  overflow: auto;
}

.publication-groups__users-col {
  max-width: fit-content;
}

.publication-groups__wrap-col {
  overflow: auto;
}

.publication-groups__items-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.add-to-list-btn {
  display: flex;
  align-self: flex-end;
  .icon_add_to_list {
    margin-left: .8rem;
  }
  &:hover {
    .icon_add_to_list {
      filter: brightness(5) sepia(1) hue-rotate(-70deg) saturate(0);
    }
  }
}

.admin-ihs__wrap-body {
  .admin-ihs__table-head-row.main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    font-weight: initial;
    font-size: 12px;
    display: inline-block;
    min-width: 100%;
  }
}

.ihs-content__wrap-body {
  .ihs-content__table-head-row.main-table__head-row .main-is-disabled {
    text-align: center;
    padding-right: 0;
    font-weight: initial;
    font-size: 12px;
    display: inline-block;
    min-width: 100%;
  }
}