$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;

$la-plus: "\f2c2";
$la-gear: "\f20c";
$icon-check: "\ea10";
$arrow-left: "\f120";
$btn-arrow-left: "\f111";
$btn-arrow-right: "\f112";
$la-arrow-down: "\f110";
$la-arrow-up: "\f113";
$icon-user: "\f364";
$fa-sort-up: "\f0de";
$fa-sort-down: "\f0dd";
$la-icon-close: "\f342";
$la-info-circle: "\f24b";
$la-google-plus: "\f21b";
$la-instagram: "\f24d";
$la-facebook: "\f1d8";
$la-linkedin: "\f26a";
$la-twitter: "\f35a";
$la-search: "\f2eb";

@font-face {
    font-display: swap;
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf") format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff") format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "LineAwesome";
    src: url("../fonts/line-awesome/line-awesome.woff2") format("woff2"),
        url("../fonts/line-awesome/line-awesome.woff") format("woff"),
        url("../fonts/line-awesome/line-awesome.ttf") format("truetype"),
        url("../fonts/line-awesome/line-awesome.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Font Awesome 5 Free";
    src: url("../fonts/fontawesome/webfonts/fa-solid-900.eot");
    src: url("../fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
        url("../fonts/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"),
        url("../fonts/fontawesome/webfonts/fa-solid-900.woff") format("woff"),
        url("../fonts/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"),
        url("../fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
    font-style: normal;
    font-weight: 900;
}

.fa,
.fas {
    font-family: "Font Awesome 5 Free";
    font-display: swap;
    font-weight: 900;
    font-size: 1.6rem;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    font-display: swap;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.arrow-down:after {
    content: "#{$la-arrow-down}";
}
.arrow-up:after {
    content: "#{$la-arrow-up}";
}

.fa-sort-up {
    &:before {
        content: $fa-sort-up;
    }
}

.la-icon-close {
    &:before {
        content: $la-icon-close;
    }
}

.la-info-circle {
    &:before {
        content: $la-info-circle;
    }
}

.fa-sort-down {
    &:before {
        content: $fa-sort-down;
    }
}

.la {
    display: inline-block;
    font: normal normal normal 16px/1 "LineAwesome";
    font-display: swap;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}

.btn-arrow-left {
    &:before {
        content: $btn-arrow-left;
    }
}

.btn-arrow-right {
    &:before {
        content: $btn-arrow-right;
    }
}

.arrow-left {
    &:before {
        content: $arrow-left;
    }
}

.la-plus {
    &:before {
        content: $la-plus;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.la-google-plus {
    &:before {
        content: $la-google-plus;
    }
}

.la-instagram {
    &:before {
        content: $la-instagram;
    }
}

.la-facebook {
    &:before {
        content: $la-facebook;
    }
}

.la-linkedin {
    &:before {
        content: $la-linkedin;
    }
}

.la-twitter {
    &:before {
        content: $la-twitter;
    }
}

.la-search:before {
    &:before {
        content: "\f2eb";
    }
}
