@import "variable.scss";

.main-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  font: 500 1.4rem/2.4rem $font;
  color: white;
  border-radius: 0.4rem;
  align-self: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  min-width: max-content;

  &_white {
    border: 0.1rem solid $primary;
    background: #ffffff;
    color: $primary;

    &:hover {
      border-color: $primary;
      background-color: $primary;
      color: #ffffff !important;
    }

    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    &:active {
      border-color: $primary;
      background-color: $primary;
      color: #ffffff !important;
    }
  }

  &_error {
    border: 0.1rem solid $clr-error;
    background: #ffffff;
    color: $clr-error;

    &:hover {
      border-color: $clr-error_hover;
      background-color: $clr-error_hover;
      color: #ffffff !important;
    }

    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }

    &:active {
      border-color: $clr-error_hover;
      background-color: $clr-error_hover;
      color: #ffffff !important;
    }
  }

  &_primary {
    border: 0.1rem solid $primary;
    background-color: $primary;

    &:hover {
      border-color: $primary_hover;
      background-color: $primary_hover;
    }

    &.focus {
      border-color: $primary_hover;
      background-color: $primary_hover;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    &:active {
      border-color: $primary_active;
      background-color: $primary_active;
    }
  }

  &.disabled {
    position: relative;
    opacity: 0.3;
    box-shadow: none;
    text-shadow: none;
    pointer-events: none;
  }

  &__general:first-child {
    margin-left: 0;
  }

  &__general {
    padding: 0.7rem 1.8rem;
    margin-left: 1.6rem;
  }

  &__upload {
    display: block;
    padding: 0.7rem 1.8rem;
    margin-bottom: 1.5rem;
  }
}

.toggle-btn {
  &__general {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .main-btn__general {
      margin-left: 0;
    }
  }

  &__primary {
    color: #000;

    &:first-of-type {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-of-type {
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }

  &__primary-bg {
    background: rgb(239, 239, 239);
  }

  &__active {
    color: #fff;
    background-color: #257ad1;
  }

  @media screen and (max-width: 1126px) {
    &__primary {
      font-size: 1.2rem;
      padding: 0.6rem 1rem;
    }
  }
}
