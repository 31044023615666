html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  font: inherit;
  vertical-align: baseline;
}

.technical-content p {
  margin-block-end: 0 !important;
}

br {
  height: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

#css-render-form *:not(img) {
  all: revert;
}

.main-header__alerts .main-header__alerts-item-text {
  *:not(img) {
    all: revert;
  }

  div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button, textarea {
    margin: 0;
    padding: 0;
    border: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:focus {
  outline: 0;
}
ins, a {
  text-decoration: none;
}
select{
  -webkit-appearance: none;
}
input:focus, textarea:focus{
  border: .1rem solid #4990EF;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}