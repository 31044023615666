$font-path: "../fonts/";

@font-face {
    font-display: swap;
    font-family: "Fira Sans";
    src: url("#{$font-path}FiraSans/FiraSans-Regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}FiraSans/FiraSans-Regular.woff") format("woff"),
        url("#{$font-path}FiraSans/FiraSans-Regular.ttf") format("truetype"),
        url("#{$font-path}FiraSans/FiraSans-Regular.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Fira Sans";
    src: url("#{$font-path}FiraSans/FiraSans-Medium.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}FiraSans/FiraSans-Medium.ttf") format("truetype"),
        url("#{$font-path}FiraSans/FiraSans-Medium.woff") format("woff"),
        url("#{$font-path}FiraSans/FiraSans-Medium.svg") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Fira Sans";
    src: url("#{$font-path}FiraSans/FiraSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}FiraSans/FiraSans-Bold.woff") format("woff"),
        url("#{$font-path}FiraSans/FiraSans-Bold.ttf") format("truetype"),
        url("#{$font-path}FiraSans/FiraSans-Bold.svg") format("svg");
    font-weight: bold;
    font-style: normal;
}

$font: "Fira Sans", sans-serif;
