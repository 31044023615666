$primary: #3487db !important;
$primary_hover: darken(
        $color: #3487db,
        $amount: 5,
) !important;
$primary_hover_text: darken(
        $color: #3487db,
        $amount: 10,
) !important;
$primary_active: darken(
        $color: #3487db,
        $amount: 7,
) !important;
$text-hight: #333333;
$text-medium: #778ca2;
$text-medium_hover: darken(
        $color: #778ca2,
        $amount: 5,
) !important;
$text-medium_active: darken(
        $color: #778ca2,
        $amount: 7,
) !important;
$clr-error: #f56c6c !important;
$clr-error_hover: darken(
        $color: #f56c6c,
        $amount: 10,
) !important;
$clr-danger: #ffab00 !important;
$clr-purple: #a56fd5 !important;
$clr-current: #27ae60 !important;
$bright-blue: #2d9cdb !important;
$bright-blue_hover: darken(
        $color: #2d9cdb,
        $amount: 10,
) !important;
$dark-blue: #2f80ed !important;

.main-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  overflow: hidden;
  background-color: #f4f4f4;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  margin-left: 1.2rem;
  transition: background-color 0.15s ease-in-out;

  &:first-child {
    margin-left: 0;
  }

  &.oval {
    width: auto;
    padding: 0 1.2rem;
    border-radius: 2.8rem;
  }

  &:hover {
    background-color: darken($color: #f4f4f4, $amount: 7);
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, 0.5);
  }

  &:active {
    background-color: darken($color: #f4f4f4, $amount: 7);
  }
}

.main-square-logo {
  @include square();
}

.clr-red {
  color: $clr-error;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: $clr-error_hover;
  }
}

.clr-yellow {
  color: $clr-danger;
}

.clr-green {
  color: $clr-current;
}

.clr-primary {
  color: $primary;
}

.clr-bright-blue {
  color: $bright-blue;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: $bright-blue_hover;
  }
}

.clr-dark-blue {
  color: $dark-blue;
}

.gray-text {
  color: $text-medium;
}

.hover-gray:hover {
  color: #778ca2 !important;
}

.hover-yellow:hover {
  color: $clr-danger;
}

.hover-red:hover {
  color: $clr-error;
}

.hover-primary:hover {
  color: $primary;
}

.hover-green:hover {
  color: $clr-current;
}

//media

$mobile-screen: 480px;
$tablet-screen: 768px;
$tablet-large-screen: 1024px;

@mixin mobile {
  @media screen and (max-width: #{$mobile-screen}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet-large-screen}) {
    @content;
  }
}

@mixin tablet-large-screen {
  @media screen and (max-width: #{$tablet-large-screen}) {
    @content;
  }
}

