@media screen and (max-width: 1200px){
	.main-content__top-col:not(:nth-child(4)){
		margin-right: 2.5rem;
	}
}
@media screen and (max-width: 1024px){
	.add-company__form-block-flex{
		flex-direction: column;
	}
	.add-company__form-logo{
		width: auto;
	}
}