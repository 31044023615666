@import 'reset.scss';
@import 'mixin.scss';
@import 'fonts.scss';
@import 'iconmoon.scss';
@import 'variable.scss';
@import 'buttons.scss';
@import '~@react-pdf-viewer/core/lib/styles/index.css';
@import '~@react-pdf-viewer/default-layout/lib/styles/index.css';
@import 'general.scss';
@import 'responsive.scss';
/*----------------------*/
@import '~react-day-picker/lib/style.css';
/*----------------------*/
@import 'media.scss';
/*----------------------*/
@import 'change.scss';
/*----------------------*/
@import 'zendesk.scss';
